


















































































































import { Component, Vue, PropSync, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { ITestResult, IFile, ISystem, IAssay, ICommandSequence } from '@/interfaces';
import { dispatchGetSystems, dispatchUpdateUser } from '@/store/admin/actions';
import { dispatchCheckApiError } from '@/store/main/actions';
import { readAdminOneSystem } from '@/store/admin/getters';

import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component
export default class ViewAssay extends Vue {
  // @PropSync("assay", {type: Object}) public syncedAssay!: IAssay;
  public assay: IAssay = {} as IAssay;
  public commandSequence: ICommandSequence = {} as ICommandSequence;
  /* @ModelSync('value', 'change', { type: Object })
  public readonly assay!: IAssay;*/

  public async loadAssay() {
    const response = await api.getAssay(readToken(this.$store), +this.$router.currentRoute.params.id);
    if (response) {
      console.log('Loaded assay');
      this.assay = response.data;
    }
  }

  public async loadCommands() {
    const response = await api.getCommandSequence(readToken(this.$store), +this.$router.currentRoute.params.id);
    if (response) {
      console.log('Loaded commandseq');
      this.commandSequence = response.data;
    }
  }

  public async mounted() {
    this.loadAssay();
    this.loadCommands();
  }

  public cancel() {
    this.$router.back();
  }


}



/*
    name: string;
    guid: string;
    test_type: string;
    lock_comments: string;
    is_clia_waived: boolean;
    creation_time: string;
    modification_time: string;
    analysis_type?: number;
    assay_class?: number;
    assay_type: number;
    assay_version: number;
    company_code: number;
    product_code: number;
    assay_index?: number;
    command_sequence_format: number;
    data_reduction_algorithm: number;
    comments: string;
    ext_control_frq?: number;
    need_ext_control?: boolean;
    need_lsp: boolean;
    air_chamber?: number;
    overflow_chamber?: number;
    // picture: string;
    prep_cartridge_shelflife: number;
    use_background_subtraction: boolean;
    use_melt_all_peaks: boolean;
    cartridge_id: number;

    analytes: IAnalyte[];

    has_melt_analytes: boolean;

export interface IAnalyte {
    assay_id: number;
    name: string;
    reporter_name: string;
    assay_index: number;
    analyte_index: number;
    is_pcr: boolean;
    is_control: boolean;
    is_melt_target: boolean;
    is_probe: boolean;
}
*/


